import {
  createItemsFromDocuments,
  deleteDocument,
  updateDocument,
} from "helpers/api"
import { useTransactionIdParam } from "helpers/params"
import { apiClient, useAPIMutation } from "lib/api"
import {
  type APIDocument,
  encodeUpdatable as encodeUpdatableDocument,
  UpdatableDocument,
} from "models/Document"

export function useDeleteDocuments(
  transactionId: string,
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  return useAPIMutation(
    (ids: string[]) => Promise.all(ids.map((id) => deleteDocument(id))),
    {
      onMutate: (ids) => {
        apiClient.setQueryData<APIDocument[] | undefined>(
          [transactionId, "documents", { inQueue: true }],
          (old) => {
            if (old) {
              return old.filter((doc) => !ids.includes(doc.uuid))
            }
          }
        )
      },
      onSuccess: () => {
        apiClient.refetchQueries([
          transactionId,
          "documents",
          { inQueue: true },
        ])
        options.onSuccess?.()
      },
      onError: () => {
        options.onError?.()
      },
    }
  )
}

export function useUpdateDocument(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation(
    (doc: UpdatableDocument) => updateDocument(encodeUpdatableDocument(doc)),
    {
      onSuccess: () => {
        apiClient.refetchQueries([
          transactionId,
          "documents",
          { inQueue: true },
        ])
        options.onSuccess?.()
      },
      onError: () => {
        options.onError?.()
      },
    }
  )
}

export function useBulkUpdateDocuments(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation(
    (docs: UpdatableDocument[]) =>
      Promise.all(
        docs.map((doc) => updateDocument(encodeUpdatableDocument(doc)))
      ),
    {
      onSuccess: () => {
        apiClient.refetchQueries([
          transactionId,
          "documents",
          { inQueue: true },
        ])
        options.onSuccess?.()
      },
      onError: () => {
        options.onError?.()
      },
    }
  )
}

export function useCreateItemsFromDocuments(options: {
  onError?: (e: unknown) => void
  onSuccess?: (
    d: {
      document_id: string
      item_id: string
    }[]
  ) => void
}) {
  let transactionId = useTransactionIdParam()
  return useAPIMutation(
    (docIds: string[]) => createItemsFromDocuments(docIds),
    {
      onSuccess: (data) => {
        if (data) {
          apiClient.refetchQueries([
            transactionId,
            "documents",
            { inQueue: true },
          ])
          apiClient.refetchQueries(["transactions", transactionId, "items"])
          options.onSuccess?.(data)
        }
      },

      onError: (e) => {
        options.onError?.(e)
      },
    }
  )
}
