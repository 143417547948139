import { Document, type SigningType } from "models/Document"
import { type FileStatus } from "./operations"

export type FileDoc = {
  fields?: { [key: string]: string }
  fileData?: File
  id: string
  isSelected?: boolean
  name: string
  signingType: SigningType
  status: FileStatus
  url?: string
  uuid?: string
  inQueue?: boolean
}

export type State = {
  acceptedFiles: any[]
  files: FileDoc[]
  pendingFileVersions: string[]
}

export const INITIAL_STATE: State = {
  acceptedFiles: [],
  files: [],
  pendingFileVersions: [],
}

type Action =
  | { type: "ADD_FILE"; file: FileDoc }
  | { type: "ADD_FILES"; files: FileDoc[] }
  | { type: "CLEAR" }
  | { type: "BULK_UPDATE_DOCUMENTS"; properties: any }
  | { type: "REMOVE_DOCUMENTS"; documentIds: string[] }
  | { type: "REPLACE_FILE"; file: FileDoc }
  | { type: "UPDATE_DOCUMENTS"; documents: FileDoc[] }
  | { type: "MERGE_DOCUMENTS"; documents: FileDoc[] }
  | { type: "UPDATE_FILE"; file: FileDoc }
  | { type: "UPDATE_FILE_STATUS"; file: FileDoc }
  | { type: "UPLOAD_SUCCESS"; data: any }

export type ExternalDispatch = (action: Action) => void

export default function Reducer(state: State, action: Action): State {
  switch (action.type) {
    case "ADD_FILE":
      return { ...state, files: [...state.files, action.file] }
    case "ADD_FILES":
      return { ...state, files: [...state.files, ...action.files] }
    case "CLEAR":
      return INITIAL_STATE
    case "BULK_UPDATE_DOCUMENTS":
      return {
        ...state,
        files: state.files.map((file) => ({ ...file, ...action.properties })),
      }
    case "REMOVE_DOCUMENTS": {
      return {
        ...state,
        files: state.files.map((file) => {
          if (action.documentIds.includes(file.id)) {
            return { ...file, status: "REMOVED" }
          }
          return file
        }),
      }
    }
    case "REPLACE_FILE": {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.file.id) {
            return {
              ...action.file,
              id: action.file.uuid || action.file.id, // uuid from API takes precedent
              name: file.name,
              signingType: file.signingType,
            }
          }
          return file
        }),
      }
    }
    case "UPDATE_DOCUMENTS": {
      return {
        ...state,
        files: state.files.map((file) => {
          const doc = action.documents.find((d) => d.id === file.id)

          if (file.id === doc?.id) {
            return { ...file, ...doc }
          }

          return file
        }),
      }
    }
    case "MERGE_DOCUMENTS": {
      const isInitial = state.files.length === 0

      const localFiles = state.files.map((file) => {
        if (file.status === "REMOVED") {
          // ignore any API changes to removed files
          return file
        }

        const doc = action.documents.find((d) => d.id === file.id)
        if (doc) {
          return {
            ...file,
            ...doc,
            name: file.name,
            signingType: file.signingType, // keep any local changes to be synced later
            isSelected:
              file.isSelected ||
              (file.status !== doc.status &&
                doc.status === "CONVERSION_COMPLETE"),
          }
        }

        return file
      })

      const newDocuments = action.documents
        .filter((doc) => !state.files.find((file) => file.id === doc.id))
        .map((doc) => ({ ...doc, isSelected: isInitial }))

      const files = [...localFiles, ...newDocuments]

      return {
        ...state,
        files,
      }
    }
    case "UPDATE_FILE": {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.file.id) {
            return { ...file, ...action.file }
          }
          return file
        }),
      }
    }
    case "UPDATE_FILE_STATUS": {
      return {
        ...state,
        files: state.files.map((file) => {
          if (file.id === action.file.id) {
            return { ...file, status: action.file.status }
          }
          return file
        }),
      }
    }
    case "UPLOAD_SUCCESS":
      return {
        ...state,
        acceptedFiles: state.acceptedFiles.map((file) => {
          if (action.data.ids.includes(file.fvId)) {
            return {
              ...file,
              uploading: false,
            }
          }
          return file
        }),
        pendingFileVersions: state.pendingFileVersions.filter(
          (fv) => !action.data.ids.includes(fv)
        ),
      }

    default:
      return state
  }
}

export function documentsToFileDocs(documents: Document[]): FileDoc[] {
  return documents.map((doc) => ({
    id: doc.id,
    name: doc.name,
    numPages: doc.numPages,
    status: doc.status,
    signingType: doc.signingType,
  }))
}
