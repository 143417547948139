import { v4 as uuid } from "uuid"

import { createDocument, postPresignedUrl, uploadFileToUrl } from "helpers/api"

import { SigningType, encode } from "models/Document"
import { type FileDoc, ExternalDispatch } from "./reducer"

export function formatFileName(path: string) {
  return path.substring(0, path.lastIndexOf(".")) || path
}

export const MINIMUM_FILE_SIZE = 100 // .1KB in bytes

// Note that any status that could be considered an error needs to have the word "FAILED" in it when
// it is used in the UI in DocumentQUeueCard.js, i.e. `const hasError = status.includes("FAILED")`
export const FILE_STATUSES = [
  "FAILED_DROP",
  "FAILED_FILE_TOO_SMALL",
  "QUEUED",
  "IN_PROGRESS",
  "SELECTED_FROM_DMS",
  "WAITING_ON_DMS",
  "FETCHING_PRESIGNED_URL",
  "FAILED_FETCHING_PRESIGNED_URL",
  "SUCCESS_FETCHING_PRESIGNED_URL",
  "UPLOADING_FILE_TO_STORE",
  "SUCCESS_UPLOADING_FILE_TO_STORE",
  "FAILED_UPLOADING_FILE_TO_STORE",
  "CREATING_DOCUMENT",
  "SUCCESS_CREATING_DOCUMENT",
  "FAILED_CREATING_DOCUMENT",
  "CONVERTING_DOCUMENT",
  "SUCCESS_CONVERTING_DOCUMENT",
  "FAILED_CONVERTING_DOCUMENT",
  "GENERATING_THUMBNAILS",
  "SUCCESS_GENERATING_THUMBNAILS",
  "FAILED_GENERATING_THUMBNAILS",
  "CONVERSION_COMPLETE",
  "REMOVED",
] as const

export type FileStatus = (typeof FILE_STATUSES)[number]

export const STATUS_MESSAGES = {
  FAILED_DROP: "File type must be .pdf, .doc, or .docx",
  FAILED_FILE_TOO_SMALL: `File is smaller than the minimum size of ${
    MINIMUM_FILE_SIZE / 1000
  }kb`,
  QUEUED: "",
  IN_PROGRESS: "",
  WAITING_ON_DMS: "",
  SELECTED_FROM_DMS: "",
  FETCHING_PRESIGNED_URL: "",
  FAILED_FETCHING_PRESIGNED_URL:
    "We encountered an error attempting to upload this file",
  SUCCESS_FETCHING_PRESIGNED_URL: "",
  UPLOADING_FILE_TO_STORE: "",
  SUCCESS_UPLOADING_FILE_TO_STORE: "",
  FAILED_UPLOADING_FILE_TO_STORE:
    "We encountered an error attempting to upload this file",
  CREATING_DOCUMENT: "",
  SUCCESS_CREATING_DOCUMENT: "",
  FAILED_CREATING_DOCUMENT:
    "We encountered an error attempting to save this document",
  CONVERTING_DOCUMENT: "",
  SUCCESS_CONVERTING_DOCUMENT: "",
  FAILED_CONVERTING_DOCUMENT:
    "We encountered an error attempting to convert this document",
  GENERATING_THUMBNAILS: "",
  SUCCESS_GENERATING_THUMBNAILS: "",
  FAILED_GENERATING_THUMBNAILS:
    "We encountered an error attempting to convert this document",
  CONVERSION_COMPLETE: "",
  REMOVED: "",
}

const STEPS = FILE_STATUSES.filter((status) => !status.includes("FAILED"))

export function isPersistable(fileOrStatus: string | { status: string }) {
  let value =
    typeof fileOrStatus === "string" ? fileOrStatus : fileOrStatus.status
  // Any file with this status has not been persisted to API
  return ![
    "FAILED_DROP",
    "FAILED_FILE_TOO_SMALL",
    "QUEUED",
    "IN_PROGRESS",
    "WAITING_ON_DMS",
    "FETCHING_PRESIGNED_URL",
    "FAILED_FETCHING_PRESIGNED_URL",
    "SUCCESS_FETCHING_PRESIGNED_URL",
    "UPLOADING_FILE_TO_STORE",
    "SUCCESS_UPLOADING_FILE_TO_STORE",
    "FAILED_UPLOADING_FILE_TO_STORE",
    "CREATING_DOCUMENT",
    "FAILED_CREATING_DOCUMENT",
    "REMOVED",
  ].includes(value)
}

export function persistableFiles(files: { status: string }[]) {
  return files.filter(isPersistable)
}

export function isInProcess(fileOrStatus: string | { status: string }) {
  let value =
    typeof fileOrStatus === "string" ? fileOrStatus : fileOrStatus.status || ""

  return (
    !["QUEUED", "CONVERSION_COMPLETE", "REMOVED"].includes(value) &&
    !value.includes("FAILED")
  )
}

export function isStarted(fileOrStatus: string | { status: string }) {
  let value =
    typeof fileOrStatus === "string" ? fileOrStatus : fileOrStatus.status || ""

  return !["QUEUED", "REMOVED"].includes(value) && !value.includes("FAILED")
}

type Steps = (typeof STEPS)[number]

export function statusPercentComplete(status: Steps) {
  const index = STEPS.indexOf(status)
  if (index === -1) return 0

  return ((index + 1) / STEPS.length) * 100
}

type Message = keyof typeof STATUS_MESSAGES

export function errorMsgForFailure(status: Message) {
  return STATUS_MESSAGES[status] || "There was a problem uploading this file"
}

export type ExtendedFile = File & {
  id?: string
  signingType?: SigningType
  status?: FileStatus
  inQueue?: boolean
}

export function addFile(dispatch: ExternalDispatch) {
  return async (fileInput: ExtendedFile): Promise<FileDoc> => {
    // Need to use potentially existing id to support DMS
    const id = fileInput.id || uuid()
    const name = formatFileName(fileInput.name || "")

    const file = {
      fileData: fileInput,
      name,
      modified: fileInput.lastModified,
      id,
      signingType: fileInput.signingType || "to_be_signed",
      status: fileInput.status || "QUEUED",
      inQueue: fileInput.inQueue === undefined ? true : fileInput.inQueue,
    }

    dispatch({ type: "ADD_FILE", file })

    return file
  }
}
export function addFiles(dispatch: ExternalDispatch) {
  return (fileInputs: File[]) => {
    const files = fileInputs.map((fileInput) => {
      const id = uuid()
      const name = formatFileName(fileInput.name || "")

      return {
        fileData: fileInput,
        id,
        // Force inQueue to true since this is only called from add-documents
        inQueue: true,
        isSelected: false,
        modified: fileInput.lastModified,
        name,
        signingType: "to_be_signed" as SigningType,
        status: "QUEUED" as FileStatus,
      }
    })

    dispatch({
      type: "ADD_FILES",
      files,
    })
  }
}

export function addRejectedFile(dispatch: ExternalDispatch) {
  return (fileInput: { file: File; errors: { code: string }[] }) => {
    const id = uuid()
    const status =
      fileInput.errors[0]?.code === "file-too-small"
        ? ("FAILED_FILE_TOO_SMALL" as const)
        : ("FAILED_DROP" as const)
    const file = {
      fileData: fileInput.file,
      name: fileInput.file.name,
      modified: fileInput.file.lastModified,
      id,
      signingType: "to_be_signed" as const,
      status,
    }

    dispatch({
      type: "ADD_FILE",
      file,
    })

    return file
  }
}

export function fetchPresignedUrl(
  dispatch: ExternalDispatch,
  { transactionId }: { transactionId: string }
) {
  return async (fileInput: FileDoc): Promise<FileDoc> => {
    let file = { ...fileInput, status: "FETCHING_PRESIGNED_URL" as FileStatus }
    dispatch({ type: "UPDATE_FILE_STATUS", file })

    let fd = fileInput.fileData
    if (!fd) {
      dispatch({
        type: "UPDATE_FILE_STATUS",
        file: {
          ...file,
          status: "FAILED_FETCHING_PRESIGNED_URL",
        },
      })
      return file
    }

    try {
      const result = await postPresignedUrl(
        { id: file.id, fileData: fd },
        transactionId
      )
      if (!result) {
        throw new Error("No data from presigned url")
      }

      const { fields, url } = result
      file = { ...file, fields, url, status: "SUCCESS_FETCHING_PRESIGNED_URL" }
      dispatch({ type: "REPLACE_FILE", file })
      return file
    } catch (e) {
      file = { ...file, status: "FAILED_FETCHING_PRESIGNED_URL" }
      dispatch({ type: "UPDATE_FILE_STATUS", file })
      return file
    }
  }
}

export function uploadFile(dispatch: ExternalDispatch) {
  return async (fileInput: FileDoc): Promise<FileDoc> => {
    if (fileInput.status !== "SUCCESS_FETCHING_PRESIGNED_URL") return fileInput

    let file = { ...fileInput, status: "UPLOADING_FILE_TO_STORE" as FileStatus }
    dispatch({ type: "UPDATE_FILE_STATUS", file })

    try {
      if (!file.url || !file.fields || !file.fileData) {
        throw new Error("No url or data to upload file to")
      }
      if (file.fileData) {
        await uploadFileToUrl(file.url, file.fileData, file.fields)
      }
      file = { ...file, status: "SUCCESS_UPLOADING_FILE_TO_STORE" }
      dispatch({ type: "UPDATE_FILE_STATUS", file })
      return file
    } catch (e) {
      console.log(e)
      file = { ...file, status: "FAILED_UPLOADING_FILE_TO_STORE" }
      dispatch({ type: "UPDATE_FILE_STATUS", file })
      return file
    }
  }
}

export function addDocument(
  dispatch: ExternalDispatch,
  { transactionId }: { transactionId: string }
) {
  return async (fileInput: FileDoc): Promise<FileDoc> => {
    if (fileInput.status !== "SUCCESS_UPLOADING_FILE_TO_STORE") return fileInput

    let file = { ...fileInput, status: "CREATING_DOCUMENT" as FileStatus }
    dispatch({ type: "UPDATE_FILE_STATUS", file })
    try {
      if (!file.fields?.key) {
        throw new Error("Attempting to create document with no fields")
      }

      const data = await createDocument(
        encode({
          id: file.id,
          transactionId,
          name: file.name,
          uploadedFileKey: file.fields.key,
          inQueue: file.inQueue || false,
          signingType: file.signingType,
        })
      )

      if (!data) {
        throw new Error("No data returned from create document api")
      }

      const dataFile: FileDoc = {
        uuid: data.uuid,
        id: file.id,
        name: fileInput.name,
        fileData: fileInput.fileData,
        status: "SUCCESS_CREATING_DOCUMENT",
        signingType: file.signingType,
      }

      dispatch({
        type: "REPLACE_FILE",
        file: dataFile,
      })

      return dataFile
    } catch (e) {
      file = { ...file, status: "FAILED_CREATING_DOCUMENT" }
      dispatch({ type: "UPDATE_FILE_STATUS", file })
      return file
    }
  }
}

// FileDoc pipe
type PipeFn = (
  file: FileDoc,
  opts?: { transactionId: string }
) => Promise<FileDoc>

const pipeAsync =
  (fns: PipeFn[]) =>
  async (x: FileDoc): Promise<FileDoc> =>
    fns.reduce(async (v, f) => await f(await v), Promise.resolve(x))

export function buildUploadOperations(
  dispatch: ExternalDispatch,
  operations = [addFiles]
) {
  const [dispatchAddFiles] = operations.map((f) => f(dispatch))
  return async function (files: File[]) {
    dispatchAddFiles?.(files)
  }
}

export function triggerDMSWatch(
  file: ExtendedFile,
  dispatch: ExternalDispatch
) {
  addFile(dispatch)(file)
}

export function triggerQueuedUpload(
  file: FileDoc,
  dispatch: ExternalDispatch,
  transactionId: string,
  operations = [fetchPresignedUrl, uploadFile, addDocument]
) {
  const operationThunks = operations.map((f) => f(dispatch, { transactionId }))
  pipeAsync(operationThunks)(file)
}

export async function triggerUpload(
  file: ExtendedFile,
  dispatch: ExternalDispatch,
  transactionId: string,
  operations = [fetchPresignedUrl, uploadFile, addDocument]
) {
  const addedFile = await addFile(dispatch)(file)
  const operationThunks = operations.map((f) => f(dispatch, { transactionId }))
  pipeAsync(operationThunks)(addedFile)
}
